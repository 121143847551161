$foodstarter-light-grey: rgb(174, 171, 171);
$foodstarter-blue: #1b66b3;
$edeka-blue-color: #1b66b3; // EDEKA.de #1561ac; //rgb(63, 111, 175);
$btn-primary-color: $edeka-blue-color;
$border-color: #e0e0e0;
$screen-xs-min: 600px;

// STYLES FROM LION

#cookiesConsent {
  background: $foodstarter-light-grey;
  color: #fff;
  padding: 15px 45px;
  width: 100%;
  bottom: 0; // Show the banner at the bottom of the page
  position: fixed;
  z-index: 99;

  @media (max-width: $screen-xs-min) {
    padding: 10px 15px;
  }

  &.hidden {
    visibility: hidden;
  }

  a {
    text-decoration: underline;
  }

  .consentText {
    display: block;
    margin: 0 auto;

    @media (min-width: 1024px) {
      width: 90%;
    }
  }

  .consentAcceptor {
    margin-top: 35px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;

    @media (max-width: $screen-xs-min) {
      justify-content: space-between;
    }
  }
}

.changeSettings {
  white-space: normal;

  @media (max-width: $screen-xs-min) {
    padding: 5px;
    border: 1px solid white;
    text-align: left;
    font-size: 14px;
    width: 160px;
  }
}

.acceptCookies {
  background: $foodstarter-blue;
  color: #fff;
  width: 160px;
}

.cookiesSettingsContainer {
  background: transparent;
  display: flex;
  justify-content: center;
  top: 20%;
  position: absolute;
  z-index: 999;

  &.hidden {
    visibility: hidden;
  }
}

#cookiesSettings {
  width: 90%;
  z-index: 99;
  background: #fff;
  border: 1px solid $border-color;
  border-radius: 3px;
  padding: 30px 45px;

  @media (max-width: $screen-xs-min) {
    padding: 10px 15px;
  }

  a {
    color: #4f4f4f;
    text-decoration: underline;
  }

  .icon {
    width: 50px;
  }
}

.iconContainer {
  padding-right: 30px;
  padding-top: 25px;
  vertical-align: top;

  @media (max-width: $screen-xs-min) {
    padding-right: 20px;
    padding-top: 13px;
  }
}

.textContainer {
  padding: 15px;

  @media (max-width: $screen-xs-min) {
    padding: 0;
  }
}

.checkboxContainer {
  padding: 30px;
  vertical-align: top;

  @media (max-width: $screen-xs-min) {
    padding-top: 9px;
    padding-right: 10px;
    padding-left: 0;
  }
}

.actionContainer {
  display: flex;
  justify-content: flex-end;

  @media (max-width: $screen-xs-min) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.shortText {
  visibility: visible;
}

// ADDITIONAL STYLES FOR SHOPLOCAL

.hidden {
  display: none !important;
}

#cookiesConsent {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 600;
}

.changeSettings {
  color: #fff;

  @media (max-width: $screen-xs-min) {
    padding: 0;
    border: none;
    text-align: center;
    width: auto;
  }
}

.iconContainer {
  padding: 15px 15px 0 0;
}


.checkboxContainer {
  padding: 15px 0 0 15px;
}
